<template>
  <div>
      <el-table
      :data="tableData"
      border
      style="width: 100%" v-loading="loading" height="90%">

       <el-table-column
        prop="goodName"
        label="商品信息">
      </el-table-column>

      <el-table-column
        prop="skuid"
        label="skuId">
      </el-table-column>

      <el-table-column
        prop="orderid"
        label="订单号">
      </el-table-column>

      <el-table-column
        prop="parentid"
        label="父单号">
      </el-table-column>

      <el-table-column
        prop="status"
        label="订单状态">
      </el-table-column>
       <el-table-column
        label="订单时间"
        >
        <template slot-scope='scope'>
         <p>下单时间:{{scope.row.payAt}}</p>
         <p>完成时间:{{scope.row.finishAt}}</p>
        </template>
      </el-table-column>

      <el-table-column
      prop="goodNum"
        label="商品数量">
      </el-table-column>

      <el-table-column
        prop="planAmount"
        label="预估计佣金额">
      </el-table-column>     

      <el-table-column
        prop="realAmount"
        label="实际计佣金额">
      </el-table-column>

       <el-table-column
        prop="secret"
        label="密令词">
      </el-table-column>

   </el-table>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { getViolateList } from "@/api/violateorder";
import router from '@/router';

  export default {
    
    data() {
      return {
      projectId: '',
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      };
    },
    mounted(){
      this.projectId = this.$route.params.projectId
this.search(this.$route.params.projectId)
    },
    methods: {
      async search(val){
           this.loading = true;
        await getViolateList({projectId: this.projectId, pageNo: this.currentPage, pageSize: this.pageSize})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      this.tableData = res.data.records;
      this.total = res.data.total;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    }
    }
  };
</script>

